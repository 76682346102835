import Alpine from 'alpinejs';
import { gsap } from "gsap";
import Plyr from 'plyr';

document.addEventListener('alpine:init', () => {

  Alpine.data('question', () => ({
    questions: require('/data.json').questions,
    questionIncrement: 0,
    currentQuestion: null,
    player: null,
    isPlaying: false,
    error: false,
    timeLeft: 0,
    init(){
      if(this.questionIncrement == this.questions.length) {
        this.questionIncrement = 0;
      }
      this.currentQuestion = Object.values(this.questions)[this.questionIncrement];
      this.isPlaying = false;
      this.$nextTick(() => {
        this.player = new Plyr('.js-player', {
          storage: true,
          debug: false,
          controls: false
        });
        this.player.on('ready', event => {
          this.playInBackground();
        });
        this.player.on('ended', event => {
          this.gotoNextQuestion();
        });
      });
    },
    playInBackground() {
      this.player.muted = true;
      this.player.loop = true;
      this.player.play();
    }, 
    play() {
      if(this.isPlaying) {
        if(!this.error) {
          this.error = true;
          let time = this.player.duration - this.player.currentTime;
          let minutes = Math.floor(time / 60);
          let seconds = (Math.floor(time) - minutes) % 60;
          this.timeLeft = minutes+':'+seconds;
          setTimeout(() => {
            this.error = false;
          }, 2000);
        }
        return false;
      }

      this.isPlaying = true;
      this.player.muted = false;
      this.player.loop = false;
      this.player.currentTime = 0;
      this.player.play();
    },
    gotoNextQuestion(){
      window.appear.update();
      setTimeout(() => {
        console.log(this.questionIncrement);
        this.questionIncrement = this.questionIncrement + 1;
        this.player.destroy();
        setTimeout(function(){
          this.init();
        }.bind(this), 100);
      }, 100);
    }
  }))

});
